import { reactive, toRefs } from '@vue/composition-api'
import FormOptionsService from "@/services/formOptions/formOptions.service";

const balanceTransactionTranslations = {
    in_progress: "В обробці",
    failed: "Помилка",
    success: "Виконано",
};
export function useTransactionStatuses() {
    const data = reactive({
        transactionStatuses: null,
    })

    const fetchTransactionStatuses =  async () => {
        const response = await FormOptionsService.transactionStatuses();
        response?.data?.items?.forEach(el => {
            el.translated = balanceTransactionTranslations[el.title]
        })
        data.transactionStatuses = response?.data.items;
    };

    const getTransactionStatusesTitle = (title) => {
        return balanceTransactionTranslations[title];
    }

    return { fetchTransactionStatuses, ...toRefs(data), getTransactionStatusesTitle };

}