import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useBalancesList() {
    // Use toast
    const toast = useToast()

    const refBalanceListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        { key: 'id', label: '#', sortable: false, mobile: true, tdClass: 'custom-media-cell' },
        { key: 'invoiceStatus', sortable: false },
        { key: 'driver', label: 'Водій', sortable: false, mobile: true, tdClass: 'custom-media-cell' },
        { key: 'manager', label: 'Менеджер', sortable: false },
        { key: 'amount', label: 'Сума', sortable: false, formatter: val => `${(val / 100).toFixed(2)} грн` },
        { key: 'typeAccount', label: "Тип рахунку", sortable: false },
        { key: 'isExecuted', label: "Статус", sortable: false },
        { key: 'createdAt', label: 'Створений', sortable: false, mobile: true },
        { key: 'executedAt', label: 'Виконаний', sortable: false },
        { key: 'actions', label: 'Дії', mobile: true, tdClass: 'custom-media-cell' },
    ]
    const perPage = ref(10)
    const totalBalances = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusFilter = ref(null)
    const driverFilter = ref(null)
    const cashTypeFilter = ref(null)
    const dateFilter = ref(null)

    const dataMeta = computed(() => {
        const localItemsCount = refBalanceListTable.value ? refBalanceListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalBalances.value,
        }
    })

    const refetchData = () => {
        refBalanceListTable.value.refresh()
    }

    watch([currentPage, perPage], () => {
        refetchData()
    })

    watch([searchQuery, statusFilter, driverFilter, cashTypeFilter, dateFilter], () => {
        currentPage.value = 1
        refetchData()
    })

    const fetchBalances = (ctx, callback) => {
        store
            .dispatch('app-balance/fetchBalances', {
                q: searchQuery.value,
                size: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                filterProfileId: driverFilter.value,
                filterTypeAccount: cashTypeFilter.value,
                filterStatusSimplified: statusFilter.value?.id,
                filterDateFrom: dateFilter.value ? dateFilter.value.split(' - ')[0] : '',
                filterDateTo: dateFilter.value ? dateFilter.value.split(' - ')[1] : '',
            })
            .then(response => {
                const balances = response.data.items
                const total = response.data.cursor.totalItems

                callback(balances)
                totalBalances.value = total
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error fetching balances' list",
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }



    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveBalanceStatusVariantAndIcon = directionId => {
        if (directionId == 2) return { variant: 'danger', icon: 'CheckCircleIcon' }
        if (directionId == 1) return { variant: 'success', icon: 'SendIcon' }
        return { variant: 'secondary', icon: 'XIcon' }
    }

    const resolveClientAvatarVariant = directionId => {
        if (directionId == 2) return 'primary'
            // if (directionId === 'Paid') return 'danger'
        if (directionId == 1) return 'secondary'
            // if (directionId === 'Draft') return 'warning'
            // if (directionId === 'Sent') return 'info'
            // if (directionId === 'Past Due') return 'success'
        return 'primary'
    }

    const resolveCashVariant = cashType => {
        if (cashType == '1') return 'warning'
        if (cashType == '2') return 'info'
        return 'primary'
    }

    const resolveCash = cashType => {
        if (cashType == '1') return 'Готівковий'
        if (cashType == '2') return 'Безготівковий'
    }

    const resolveStatusVariant = status => {
        if (status == 1) return 'primary'
        if (status == 2) return 'danger'
        if (status == 3) return 'success'
        return 'primary'
    }

    const resolveStatus = status => {
        if (status == 1) return 'В обробці'
        if (status == 2) return 'Помилка'
        if (status == 3) return 'Виконаний'
    }

    const statusOptions = [
        { label: 'Виконаний', value: true },
        { label: 'В процесі', value: false },
    ]

    const cashTypeOptions = [
        { label: 'Готівковий', value: 1 },
        { label: 'Безготівковий', value: 2 },
    ]

    return {
        fetchBalances,
        tableColumns,
        perPage,
        currentPage,
        totalBalances,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refBalanceListTable,

        statusFilter,
        driverFilter,
        cashTypeFilter,
        dateFilter,

        statusOptions,
        cashTypeOptions,

        resolveBalanceStatusVariantAndIcon,
        resolveClientAvatarVariant,

        resolveCashVariant,
        resolveCash,

        resolveStatusVariant,
        resolveStatus,

        refetchData,
    }
}